import i18n from 'assets/i18n'
import dayjs from "dayjs"
import { Action } from 'redux-actions'
import { Country } from 'screens/accounts_oracle/stores/types'
import { MyColumnType } from 'screens/accounts_postgre/hooks/useBuyersColumns'
import { Country as CountryRDS } from 'screens/accounts_postgre/stores/types'
import { loginActions } from 'screens/login/stores/actions'
import { RpUser } from 'screens/login/stores/types'
import { IAlertType } from '../components/RPAlert/stores/types'
import { pkceLogin, pkceRenewToken } from '../services/authentication/PkceOidcAuthentication'
import store from "../stores/store"
import { ApiData, FetchReduce, SortDirection, UpdateRecordResponse } from './types'
import { AUTH_FLOWS } from './constants'

export const delay = (ms: number) => new Promise(res => setTimeout(res, ms))

// Alert for dev purposes
export const createGenericErrorAlert = (error: any): IAlertType => {
  const ret = {
    ...error,
    code: error.response?.status || error.message?.status,
    message: error.rp4errorHandling?.statusText || error.response?.statusText || error.message?.statusText,
    description: error.rp4errorHandling?.description || error.response?.request.responseURL || error.message?.request?.responseURL,
    type: 'error'
  };
  return ret;
}

export const createErrorMsg = (description: string): IAlertType => {
  return {
    message: i18n.t('Generic.Status.ERROR'),
    description,
    displayed: false,
    type: 'error'
  }
}

export const createSuccessMsg = (description: string): IAlertType => {
  return {
    message: i18n.t('Generic.Alert.Done'),
    description,
    displayed: false,
    type: 'success'
  }
}

export const getBirCode = (): string | undefined => {
  let bircode = store.getState().rp4.context.myBirCodeData?.bircode;
  return bircode;
}

export const getSelectedCountry = (): Country | undefined => {
  const countries = store.getState().rp4.oracle.accounts.countries.data;
  const bircode = getBirCode();
  let selectedCountry;
  if (bircode && countries) {
    selectedCountry = countries.filter(country =>
      country.bircodeDataList.filter(
        bircodeData => bircodeData.bircode === bircode
      ).length > 0
    )[0]
  }
  return selectedCountry
}

export const getSelectedCountryRDS = (): CountryRDS | undefined => {
  const countries = store.getState().rp4.postgre.accounts.getCountries.data;
  const bircode = getBirCode();
  let selectedCountry;
  if (bircode && countries) {
    selectedCountry = countries.countries.filter(country =>
      country.accountList.filter(
        x => x.sellerCode === bircode
      ).length > 0
    )[0]
  }
  return selectedCountry
}

export const handleTableChange = (payload: any, setPayload: any, columns: any = undefined, setColumns: any = undefined) => {
  return (pagination: any, filters: any, sorter: any) => {
    let newPayload: any = {
      ...payload,
      page: pagination.current - 1,
      size: pagination.pageSize,
      pageSize: pagination.pageSize
    }

    if (typeof sorter?.order !== 'undefined') {
      const sortDirection = SortDirection[sorter.order as keyof typeof SortDirection]
      const sortField = sorter.columnKey;
      const sort = `${sortField},${sortDirection}`

      newPayload = {
        ...newPayload,
        sort,
      }

    } else {
      delete newPayload.name;
      delete newPayload.sort;
      delete newPayload["name.dir"]
    }

    Object.keys(filters).forEach((value: string) => {
      if (!filters[value]) {
        delete newPayload[value]
      } else {
        const filterKey = filters[value]

        newPayload = {
          ...newPayload,
          [value]: value === "fileCreatedDate" ? dayjs(filterKey[0]).format("MM/DD/YYYY HH:mm:ss") : filterKey[0]
        }
      }
    })
    setPayload(newPayload)

    // Columns
    if (columns && setColumns) {
      const newColumns = columns.map((column: any) => {
        let curColumn = { ...column }

        delete curColumn.filteredValue
        if (curColumn.key in newPayload && curColumn.key in filters) {
          // Filters
          curColumn.filteredValue = filters[curColumn.key]
        }

        delete curColumn.sortOrder
        if (newPayload.sort) {
          // Sorting
          const sort = newPayload.sort.split(",")
          const sortKey = sort[0]
          const sortValue = sort[1]
          if (sortKey === curColumn.dataIndex) {
            curColumn.sortOrder = sortValue === "asc" ? 'ascend' : 'descend'
          }
        }
        return curColumn
      })
      setColumns(newColumns)
    }
  }
}

export const handleTableChangeV2 = (payload: any, setPayload: any, columns: any = undefined, setColumns: any = undefined) => {
  return (pagination: any, filters: any, sorter: any) => {
    let newPayload: any = {
      ...payload,
      page: pagination.current - 1,
      pageSize: pagination.pageSize,
    }

    if (typeof sorter?.order !== 'undefined') {
      const sortDirection = SortDirection[sorter.order as keyof typeof SortDirection]
      const sortField = sorter.columnKey;
      const sort = `${sortField},${sortDirection}`

      newPayload = {
        ...newPayload,
        sort,
      }

    } else {
      delete newPayload.name;
      delete newPayload.sort;
      delete newPayload["name.dir"]
    }

    Object.keys(filters).forEach((value: string) => {
      if (!filters[value]) {
        delete newPayload[value]
      } else {
        const filterKey = filters[value]

        if (value === "fileCreatedDate") {
          newPayload = {
            ...newPayload,
            [value]: dayjs(filterKey[0]).format("MM/DD/YYYY HH:mm:ss")
          }
        } else {
          newPayload = {
            ...newPayload,
            [value]: filterKey[0]
          }
        }
      }
    })

    // Columns
    if (columns && setColumns) {
      const newColumns = columns.map((column: MyColumnType<any>) => {
        let curColumn = { ...column }

        delete curColumn.filteredValue
        if (curColumn.filterIndex && curColumn.key && curColumn.key in newPayload && curColumn.key in filters) {
          // Filters
          curColumn.filteredValue = filters[curColumn.key]

          if (curColumn.filterIndex !== curColumn.key) {
            filters = {
              ...filters,
              [curColumn.filterIndex]: filters[curColumn.key]
            }

            newPayload = {
              ...newPayload,
              [curColumn.filterIndex]: newPayload[curColumn.key]
            }

            delete newPayload[curColumn.key]
            delete filters[curColumn.key]
          }
        }

        delete curColumn.sortOrder
        if (newPayload.sort) {
          // Sorting
          const sort = newPayload.sort.split(",")
          const sortKey = sort[0]
          const sortValue = sort[1]
          if (sortKey === curColumn.dataIndex) {
            curColumn.sortOrder = sortValue === "asc" ? 'ascend' : 'descend'
          }
        }
        return curColumn
      })
      setPayload(newPayload)
      setColumns(newColumns)
    }
  }
}

export async function getRefreshedUser() {

  try {
    const user = await pkceRenewToken();
    store.dispatch(loginActions.authLoginSuccess(user));
  }
  catch (err) {
    console.error(`getRefreshedUser`, err);
    await getLoginUser();
  }
}

export async function getLoginUser() {

  try {
    const user = await pkceLogin();
    store.dispatch(loginActions.authLoginSuccess(user));
  }
  catch (err) {
    console.error(`getLoginUser`, err);
  }
}

export function getUserFromLocalStorage(): RpUser | undefined {
  const currentLocalStorageKeys = Object.keys(localStorage).filter(
    elem => {
      if (elem.includes(AUTH_FLOWS.OKTA) || elem.includes(AUTH_FLOWS.IDP_LEGACY) || elem.includes(AUTH_FLOWS.IDP_TWIN)) {
        return true;
      }
      return false;
    }
  );
  if (currentLocalStorageKeys.length > 0) {
    let user: RpUser = new RpUser({
      ...JSON.parse(localStorage.getItem(currentLocalStorageKeys[0])!)
    });

    if (!user) return undefined;
    return user;
  }
}

export const getArgs = (payload: any): string => {
  Object.keys(payload).forEach(x => {
    const value = payload[x];
    if (x === "fileCreatedDate") {
      // const dayjs1 = dayjs(value).format("L LTS")
      const dayjs2 = dayjs(value).format("YYYY/MM/DD HH:mm:ss")
      payload[x] = dayjs2
    }
  })
  return new URLSearchParams(payload).toString()
}

function getObjectValue(obj: any, path: string): any {
  const keys = path.split('.');
  let value = obj;
  for (const key of keys) {
    if (!value) return value
    value = value[key];
    if (value === undefined) {
      return undefined;
    }
  }
  return value;
}

type FetchApiTypes = any
export function updateStateSuccessFn<T>(additionnalPropertiesToUpdate: string[], matchingKey: string) {
  return function (fetchs: FetchReduce<T>) {
    return {
      [fetchs.start]: (state: any) => {
        additionnalPropertiesToUpdate.forEach((param: keyof FetchApiTypes) => {
          state = { ...state, [param]: { ...state[param], loading: true } }
        })
        return state
      },
      [fetchs.error]: (state: any) => {
        additionnalPropertiesToUpdate.forEach((param: keyof FetchApiTypes) => {
          state = { ...state, [param]: { ...state[param], loading: false } }
        })
        return state
      },
      [fetchs.success]: (state: any, action: Action<UpdateRecordResponse<any>>) => {
        additionnalPropertiesToUpdate.forEach((param: keyof FetchApiTypes) => {
          let apiDataMultiple: ApiData<FetchApiTypes[typeof param]> = getObjectValue(state, param as string)
          let apiDataSingle = getObjectValue(apiDataMultiple.data, matchingKey)

          if (apiDataMultiple.data && "content" in apiDataMultiple.data && Array.isArray(apiDataMultiple.data?.content)) {
            apiDataMultiple.data.content = apiDataMultiple.data.content.map((x: any) => {
              const recordWrapper = action.payload.updatedRecords.find(recordWrapper =>
                getObjectValue(recordWrapper.record, matchingKey) === getObjectValue(x, matchingKey)
              )
              return recordWrapper ? recordWrapper.record : x;
            })
          } else if (apiDataSingle) {
            const matchingRecordWrapper = action.payload.updatedRecords.find(recordWrapper =>
              getObjectValue(recordWrapper.record, matchingKey) === apiDataSingle
            );

            if (matchingRecordWrapper) {
              apiDataMultiple.data = matchingRecordWrapper.record;
            }
          }

          state = { ...state, [param]: { ...apiDataMultiple, loading: false } }
        })

        return state
      }
    }
  }
};

export function fetchReduceFn<T>(fetchs: FetchReduce<T>) {
  let newState = {
    [fetchs.start]: (state: any, action: Action<any>) => ({
      ...state,
      [fetchs.param as string]: {
        ...state[fetchs.param],
        loading: true,
        payload: action.payload
      },
    }),
    [fetchs.success]: (state: any, action: Action<any>) => ({
      ...state,
      [fetchs.param as string]: {
        ...state[fetchs.param],
        data: action.payload,
        loading: false,
      },
    }),
    [fetchs.error]: (state: any) => ({
      ...state,
      [fetchs.param as string]: {
        ...state[fetchs.param],
        loading: false
      },
    })
  }

  if (fetchs.onSuccess) {
    newState = {
      ...newState,
      ...fetchs.onSuccess(fetchs)
    }
  }

  if (fetchs.additionnalParams) {
    newState = {
      ...newState,

      [fetchs.success]: (state: any, action: Action<any>) => {
        let res = {
          ...state,
          [fetchs.param as string]: {
            ...state[fetchs.param],
            data: action.payload,
            loading: false,
          },
        }

        fetchs.additionnalParams?.forEach(additionnalParam => {
          res = {
            ...res,
            [additionnalParam]: {
              ...state[additionnalParam],
              data: action.payload,
              loading: false,
            },
          }
        })

        return res
      },
    }
  }

  return newState;
}

export function capitalizeFirstLetter (seq: string): string {
  if (!seq) return '';
  return seq.charAt(0).toUpperCase() + seq.slice(1);
};