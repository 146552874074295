import { UserManager, UserManagerSettings, WebStorageStateStore } from 'oidc-client-ts'
import { RpUser } from 'screens/login/stores/types'
import store from "shared/stores/store"
import { AUTH_FLOWS } from 'shared/utils/constants';

class RpUserManager extends UserManager {

  public flow: string;

  constructor(settings: UserManagerSettings, flow: string) {
    super(settings);
    this.flow = flow;
  }
}

const settingsIDPLegacy: UserManagerSettings = {
  authority: process.env.REACT_APP_IDP_URI || '',
  client_id: process.env.REACT_APP_CLIENT_ID || '',
  acr_values: "secure/name/x509-FormLogin-Arca-Rnet-R1-R2-R3/uri",
  response_type: "code",
  scope: process.env.REACT_APP_SCOPE,
  redirect_uri: `${window.location.origin}/1ss-Oidc-Callback`,
  post_logout_redirect_uri: `${window.location.origin}/`,
  silent_redirect_uri: `${window.location.origin}/1ss-Oidc-Callback-Silent`,
  userStore: new WebStorageStateStore({ 
    prefix: AUTH_FLOWS.IDP_LEGACY + '_',
    store: localStorage 
  }),
  popupWindowFeatures: {
    location: false, // no adress bar
    toolbar: false,  // no toolbar
    width: 100,
    height: 100,
    left: 100,
    top: 500
  }
};

const settingsIDPTwin: UserManagerSettings = {
  authority: process.env.REACT_APP_IDP_URI || '',
  client_id: process.env.REACT_APP_CLIENT_ID || '',
  acr_values: "secure/name/x509-FormLogin-Rnet-R1-R2-R3/uri",
  response_type: "code",
  scope: process.env.REACT_APP_SCOPE,
  redirect_uri: `${window.location.origin}/1ss-Oidc-Callback`,
  post_logout_redirect_uri: `${window.location.origin}/`,
  silent_redirect_uri: `${window.location.origin}/1ss-Oidc-Callback-Silent`,
  userStore: new WebStorageStateStore({ 
    prefix: AUTH_FLOWS.IDP_TWIN + '_',
    store: localStorage 
  }),
  popupWindowFeatures: {
    location: false, // no adress bar
    toolbar: false,  // no toolbar
    width: 100,
    height: 100,
    left: 100,
    top: 500
  }
};

const settingsOkta: UserManagerSettings = {
  authority: process.env.REACT_APP_OKTA_URI || '',
  client_id: process.env.REACT_APP_CLIENT_ID_OKTA || '',
  metadata: {
    issuer: process.env.REACT_APP_OKTA_URI || '',
    authorization_endpoint: `${process.env.REACT_APP_OKTA_URI || ''}/v1/authorize`,
    token_endpoint: `${process.env.REACT_APP_OKTA_URI || ''}/v1/token`,
    userinfo_endpoint: `${process.env.REACT_APP_OKTA_URI || ''}/v1/userinfo`,
    jwks_uri: `${process.env.REACT_APP_OKTA_URI || ''}/v1/keys`,
    end_session_endpoint: `${process.env.REACT_APP_OKTA_URI || ''}/v1/logout`,
  },
  response_type: "code",
  scope: process.env.REACT_APP_SCOPE_OKTA,
  post_logout_redirect_uri: `${window.location.origin}`,
  redirect_uri: `${window.location.origin}/idp-redirect`,
  silent_redirect_uri: `${window.location.origin}/silent-refresh.html`,
  accessTokenExpiringNotificationTimeInSeconds: 1920, // 1920s = 32 min = 50%+ token life
  automaticSilentRenew: true,
  loadUserInfo: true,
  userStore: new WebStorageStateStore({ 
    prefix: AUTH_FLOWS.OKTA + '_',
    store: localStorage 
  }),
};

interface RpUserManagers {
  idp_legacy: RpUserManager,
  idp_twin: RpUserManager,
  okta: RpUserManager
}

const userManagers: RpUserManagers = {
  'idp_legacy': new RpUserManager(settingsIDPLegacy, AUTH_FLOWS.IDP_LEGACY),
  'idp_twin': new RpUserManager(settingsIDPTwin, AUTH_FLOWS.IDP_TWIN),
  'okta': new RpUserManager(settingsOkta, AUTH_FLOWS.OKTA)
}

const getUserManager = async (): Promise<RpUserManager> => {

  const flow = localStorage.getItem('auth_flow') ||
    store.getState().rp4.login.flow;

  if (!flow) throw new Error(`Undefined flow when creating UserManager`)

  switch (flow) {
    case AUTH_FLOWS.IDP_LEGACY:
      return userManagers.idp_legacy;
    case AUTH_FLOWS.IDP_TWIN:
      return userManagers.idp_twin;
    case AUTH_FLOWS.OKTA:
      return userManagers.okta;
    default:
      throw new Error(`Unknown flow ${flow} when creating UserManager`);
  }
};

const cleanUserManager = async (): Promise<void> => {

  try {
    const userManager = await getUserManager();
    // userManager.stopSilentRenew();
    await userManager.removeUser();
    await userManager.clearStaleState();
  }
  catch (e) {
    console.error('cleanUserManager', e);
  }
}

export const pkceRenewToken = async (): Promise<RpUser> => {

  const um = await getUserManager();
  const user = await um.signinSilent();
  return new RpUser(user);
}

export const pkceLogin = async () => {

  try {
    await cleanUserManager();
    const um = await getUserManager();
    const user = await um.signinPopup();
    return new RpUser(user);
  }
  catch (e) {
    console.error('pkceLogin', e);
    throw e;
  }
}

export const pkceLogOut = async (): Promise<void> => {

  try {
    // const um = await getUserManager();
    // await um.signoutRedirect(); // triggers an http redirect that conflicts with react route/state management
    await cleanUserManager();
  }
  catch (e) {
    console.error('pkceLogOut', e);
  }
}

export const signinPopupCallback = async (): Promise<void> => {

  const um = await getUserManager();
  return um.signinPopupCallback();
}

export const getAccessToken = async (): Promise<string | undefined> => {

  const um = await getUserManager();
  const user = await um.getUser();
  return user?.access_token;
}

let renewing = false;
export const signinSilentCallback = async (): Promise<void> => {

  if (renewing) return; // bypass multiple react mounting of callback component leading to // renew
  renewing = true;

  const um = await getUserManager();
  await um.signinSilentCallback();

  console.log(`token silently renewed at ${new Date().toISOString()}`);

  renewing = false;
  return;
}