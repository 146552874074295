import { SimplePageContainer } from '../../Styles';
import { signinSilentCallback } from 'shared/services/authentication/PkceOidcAuthentication'

// IDP only
export const OidcCallbackSilent = () => {

  signinSilentCallback()
    .catch(error => console.error('[OIDC Callback Silent Scene] - Error => ', error))

  return (
    <SimplePageContainer>
      <h1>Hello React</h1>
      <h2>I'm callback silent scene</h2>
    </SimplePageContainer>
  );
}