import axios, { AxiosPromise, AxiosRequestConfig, AxiosResponse } from 'axios'
import * as Effects from 'redux-saga/effects'
import { API_TIMEOUT, AUTH_FLOWS, DEFAULT_DELAY } from './constants'
import { apiActions } from 'shared/stores/api/actions'
import { alertActions } from '../components/RPAlert/stores/actions'
import store from "shared/stores/store"
import { loginActions } from 'screens/login/stores/actions'
import { createGenericErrorAlert, getLoginUser, getRefreshedUser } from './utils'
import { getAccessToken } from 'shared/services/authentication/PkceOidcAuthentication'

const { call, put, delay } = Effects

const isIdp = (flow: string) => {
  return [ AUTH_FLOWS.IDP_LEGACY, AUTH_FLOWS.IDP_TWIN ].includes(flow);
}

export const api = axios.create({
  // baseURL: API_BASE_URL_ORACLE,
  timeout: API_TIMEOUT,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  }
});

// Request interceptor for API calls
api.interceptors.request.use(
  async config => {
    if (!config.url?.startsWith('/oauth')) {
      config.headers.Authorization = `Bearer ${await getAccessToken()}`
      addTimeZoneOffset(config);
    } else {
      config.headers["Content-Type"] = 'application/x-www-form-urlencoded'
    }

    const flow = localStorage.getItem('auth_flow') ||
    store.getState().rp4.login.flow ||
    'N/A';

    if (flow === AUTH_FLOWS.OKTA) {
      config.headers["apikey"] = process.env.REACT_APP_APIKEY_1PO;
    }
    else {
      config.headers["apikey"] = process.env.REACT_APP_APIKEY;
    }

    return config
  },
  error => Promise.reject({ ...error })
)

let isReconnecting = false;

api.interceptors.response.use(
  response => response,
  async error => {

    // Network handling

    if ((error.code && error.code === "ERR_NETWORK") || !error.response) {
      console.log("axios network error");
      store.dispatch(loginActions.authLogout());
      return Promise.reject({ ...error, rp4errorHandling: { statusText: 'Disconnected', description: 'Disconnected' } });
    }

    // auth handling
    
    const flow = localStorage.getItem('auth_flow') ||
      store.getState().rp4.login.flow ||
      'N/A';

    const originalRequest = error.config;
    const httpStatus = (error.response && error.response.status && error.response.status) || undefined;

    // IDP

    if (isIdp(flow)) {

      if (isReconnecting) {
        console.log("idp cancel req due to pending reconnection");
        return {
          ...error.config,
          cancelToken: new axios.CancelToken((cancel: any) => cancel('Cancel repeated request'))
        };
      }

      if (httpStatus === 401 && !originalRequest._retry) {
        console.log("idp silent refresh");
        isReconnecting = true
        originalRequest._retry = true;
        try {
          await getRefreshedUser();
          isReconnecting = false;
        }
        catch (e) {
          isReconnecting = false;
        }
        return api(originalRequest);
      }
      else if (httpStatus === 401 && originalRequest._retry) {
        console.log("idp waiting for login");
        await getLoginUser();
        return api(originalRequest);
      } 
      else if (httpStatus !== 401) {
        console.log("idp other !401 error");
        return Promise.reject({ ...error });
      } 
      else {
        console.log("idp other error");
        store.dispatch(loginActions.authLogout());
        return Promise.reject({ ...error });
      }
    }

    // Okta

    if (httpStatus !== 401) {
      console.log("okta !401 error");
      return Promise.reject({ ...error });
    } 
    else {
      console.log("okta 401 error");
      store.dispatch(loginActions.authLogout());
      return Promise.reject({ ...error, rp4errorHandling: { statusText: 'Disconnected', description: 'Disconnected' } });
    }
  }
)

const addTimeZoneOffset = (config: AxiosRequestConfig) => {
  if (!config.url?.includes("timeZoneOffset")) {
    const timeZoneOffset: string = `timeZoneOffset=${- (new Date().getTimezoneOffset() / 60)}`;

    if (config.url?.includes("?"))
      config.url += `&${timeZoneOffset}`
    else
      config.url += `?${timeZoneOffset}`
  }
}

export function* apiCallWrapper<T>(fn: any, ...rest: any[]): any {
  try {
    yield put(apiActions.loadingStart())
    const response: T = yield call(fn, ...rest)
    yield delay(DEFAULT_DELAY)
    yield put(apiActions.loadingSuccess())
    return response
  } catch (err: any) {
    yield put(apiActions.loadingFail(err))

    if (!err.request.responseURL.includes("orders/ref")) {
      yield put(alertActions.alertMsg(createGenericErrorAlert(err)))
    }
  } finally {
    yield delay(DEFAULT_DELAY)
    yield put(apiActions.loadingClear())
  }
}

export function unwrapAxiosResult<T>(promise: AxiosPromise<T>): Promise<T> {
  return new Promise((resolve, reject) => {
    promise
      .then((response: AxiosResponse<T>) => {
        resolve(response.data)
      })
      .catch((error: any) => {
        reject(error)
      })
  })
}

export default api