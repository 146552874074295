import { Action } from 'redux-actions'
import { call, put, takeLatest } from 'redux-saga/effects'
import * as actions from './actions'
import { alertActions } from 'shared/components/RPAlert/stores/actions'
import * as utils from './utils'
import { LoginPayload, RpUser } from './types'
import { User } from 'oidc-client-ts'
import * as roleActions from "screens/context/stores/actions"
import { delay } from 'shared/utils/utils'
import { DEFAULT_DELAY } from 'shared/utils/constants'
import { apiCallWrapper } from 'shared/utils/api'
import { RolePrivilege } from 'screens/context/stores'
// import { navigateActions } from 'screens/navigation/stores/actions'


/**
 * Login Sagas (works like @ngrx/effects in Angular)
 */
function* Login(action: Action<LoginPayload>) {
  try {
    yield call([localStorage, 'setItem'], 'auth_flow', action.payload.flow);
    const user: RpUser = yield apiCallWrapper<RpUser>(utils.authenticatedUser)
    yield call(loginUser, user)

  } catch (error) {
    yield put(alertActions.alertMsg(utils.createAlert(error)))
    yield put(actions.loginActions.authLoginFailed())
  }
}

function* Logout() {
  try {
    yield call(utils.userLogout);
    yield call([localStorage, localStorage.clear]);
    yield put(roleActions.contextActions.setToEmptyMyBirCode());
    // yield put(navigateActions.navigate({path: '/login'}));
  } catch (error) {
    console.log('[SAGA] Logout failed: ', error)
  }
}

export function* LoginRefreshToken() {
  try {
    const user: User = yield apiCallWrapper<User>(utils.userRenewToken)
    yield call(loginUser, user)
  } catch (error) {
    yield put(alertActions.alertMsg(utils.createAlert(error)))
    yield put(actions.loginActions.authLoginFailed())
  }
}

function* LoginSuccess(action: Action<RpUser>) {
  yield put(roleActions.contextActions?.setMyRole(action.payload.getRoleData()))
  yield delay(DEFAULT_DELAY)
}

function* loginUser(user: User) {

  const rpUser = new RpUser(user);

  if (rpUser.rolePrivilege === RolePrivilege.NONE) {
    yield put(alertActions.alertMsg(utils.createAlert({
      message: {
        statusText: "Unauthorized (not enough rights)"
      }
    })));
    yield put(actions.loginActions.authLogout());
  }
  else {
    yield put(actions.loginActions.authLoginSuccess(rpUser));
  }
}

export default function* () {
  yield takeLatest(actions.Type.AUTH_LOGIN, Login)
  yield takeLatest(actions.Type.AUTH_LOGOUT, Logout)
  yield takeLatest(actions.Type.AUTH_LOGIN_REFRESH_TOKEN, LoginRefreshToken)
  yield takeLatest(actions.Type.AUTH_LOGIN_SUCCESS, LoginSuccess)
}