import { useEffect } from 'react';
import { signinSilentCallback } from 'shared/services/authentication/PkceOidcAuthentication'

// Okta only
const SilentRenewOkta = (): JSX.Element => {

    useEffect(() => {
        signinSilentCallback()
        .then(() => {
            console.log(`okta token silently renewed at ${new Date().toISOString()}`);
        })
        .catch((e) => { 
            console.error('Unable to silent renew okta', e);
        });
    }, []);

    return <></>;
};

export default SilentRenewOkta;
