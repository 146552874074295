import { RpUser } from './types'
import { api } from 'shared/utils/api'
import { pkceLogin, pkceLogOut, pkceRenewToken } from 'shared/services/authentication/PkceOidcAuthentication'
import { IAlertType } from 'shared/components/RPAlert/stores/types'

export const authenticatedUser = (): Promise<RpUser> => {
  if (api.defaults.headers.common?.Authorization) {
    delete api.defaults.headers.common.Authorization;
  }
  return pkceLogin();
}

export const userRenewToken = (): Promise<RpUser> => {
  return pkceRenewToken();
}

export const userLogout = async (): Promise<void> => {
  return pkceLogOut();
}

export const createAlert = (error: any): IAlertType => {
  return {
    ...error,
    code: error.response?.status || error.message?.status,
    message: error.response?.statusText || error.message?.statusText,
    description: error.response?.request.responseURL || error.message?.request?.responseURL,
    type: 'error'
  }
}