import { SimplePageContainer } from '../../Styles';
import { signinPopupCallback } from 'shared/services/authentication/PkceOidcAuthentication'

// IDP & OKTA
export const OidcCallback = () => {

  signinPopupCallback()
    .catch(error => console.error('[OIDC Callback Scene] - Error => ', error))

  return (
    <SimplePageContainer>
      <h1>Hello React</h1>
      <h2>I'm callback scene</h2>
    </SimplePageContainer>
  );
}